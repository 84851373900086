














































import { Component, Vue, Prop } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { getMask, InputMask } from "@/helpers/utils";
import { BSpinner } from "bootstrap-vue";
import CustomSelect from "./Select.vue";

@Component({
	components: {
		BSpinner,
		CustomSelect,
	},
	directives: {
		mask,
	},
})
export default class CustomSearch extends Vue {
	@Prop({}) id!: string;
	@Prop({ default: "search-icon" }) icon!: string;
	@Prop({ default: "" }) value!: string;
	@Prop({ default: "" }) label!: string;
	@Prop({ default: "" }) placeholder!: string;
	@Prop({ default: "" }) mask!: InputMask;
	@Prop({ default: "" }) dynamicMaskCurrentValue!: any;
	@Prop({ default: false }) isLoading!: boolean;
	@Prop({ default: false }) isVariableMask!: boolean;
	@Prop({ default: "first" }) searchType!: string;

	currentMask = "text";
	optionsMask = [
		{ name: "Nome ou Carteirinha", value: "text", key: "name" },
		{ name: "CPF", value: "cpf", key: "cpf" },
		{ name: "Data de Nascimento", value: "birth", key: "birthDate" },
		{ name: "Telefone", value: "tel", key: "phoneNumber" },
	];

	get inputMask() {
		return getMask(this.mask || this.currentMask);
	}

	changeMask(value: string) {
		this.dynamicMaskCurrentValue.type = value;
		const foundMask = this.optionsMask.find(optionMask => optionMask.value === this.currentMask);
		this.$emit("searchKey", foundMask!.key, this.searchType);
	}

	get model() {
		return this.value;
	}

	set model(value: string) {
		this.$emit("input", value);
	}
}
